import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";

import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  /*  Tooltip,
  OverlayTrigger,*/
} from "react-bootstrap";

import { Wifi, Umbrella, Bell, Info, Eye, Users } from "react-feather";

/*import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";*/

import {
  SIDEBAR_POSITION,
  SIDEBAR_BEHAVIOR,
  LAYOUT,
  THEME,
} from "../../constants";

import useTheme from "../../hooks/useTheme";
import useSidebar from "../../hooks/useSidebar";
import useLayout from "../../hooks/useLayout";

import { ReactComponent as Logo } from "../../assets/img/logo.svg";

import screenshotMixed from "../../assets/img/screenshots/MockUp_iPhone12_AlerteCata_5.png";
/*import screenshotThemeDefault from "../../assets/img/screenshots/theme-default.jpg";
import screenshotThemeDark from "../../assets/img/screenshots/theme-dark.jpg";
import screenshotThemeColored from "../../assets/img/screenshots/theme-colored.jpg";
import screenshotThemeLight from "../../assets/img/screenshots/theme-light.jpg";
import screenshotSidebarCompact from "../../assets/img/screenshots/sidebar-compact.jpg";
import screenshotSidebarRight from "../../assets/img/screenshots/sidebar-right.jpg";

import screenshotDashboardDefault from "../../assets/img/screenshots/dashboard-default.jpg";
import screenshotDashboardAnalytics from "../../assets/img/screenshots/dashboard-analytics.jpg";
import screenshotDashboardSaaS from "../../assets/img/screenshots/dashboard-saas.jpg";
import screenshotDashboardSocial from "../../assets/img/screenshots/dashboard-social.jpg";
import screenshotDashboardCrypto from "../../assets/img/screenshots/dashboard-crypto.jpg";
import screenshotPageProjects from "../../assets/img/screenshots/pages-projects-list.jpg";

import brandBootstrap from "../../assets/img/brands/bootstrap.svg";
import brandReact from "../../assets/img/brands/react.svg";
import brandRedux from "../../assets/img/brands/redux.svg";
import brandReactRouter from "../../assets/img/brands/react-router.svg";
import brandFirebase from "../../assets/img/brands/firebase.svg";
import brandCognito from "../../assets/img/brands/cognito.svg";
import brandAuth0 from "../../assets/img/brands/auth0.svg";
import brandJWT from "../../assets/img/brands/jwt.svg";
import brandESLint from "../../assets/img/brands/eslint.svg";
import brandJavaScript from "../../assets/img/brands/javascript.svg";
import brandTypeScript from "../../assets/img/brands/typescript.svg";*/
import appStoreLogo from "../../assets/img/brands/appstore-light.svg";
import playStoreLogo from "../../assets/img/brands/googleplay-light.svg";

const Navigation = () => (
  <Navbar expand="md" className="landing-navbar">
    <Container>
      <Navbar.Brand className="landing-brand" href="/">
        <Logo /> AlerteCata
      </Navbar.Brand>
      <Nav className="ms-auto" navbar>
        <Nav.Item className="d-none d-md-inline-block">
          <Nav.Link
            href="/"
            rel="noreferrer"
            active
            className="text-lg px-lg-3"
          >
            Accueil
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            href="/policy"
            rel="noreferrer"
            active
            className="text-lg px-lg-3"
          >
            Confidentialité
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-none d-md-inline-block">
          <Nav.Link
            href="mailto:support@alertecata.fr"
            active
            className="text-lg px-lg-3"
          >
            Support
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Button
        href="https://store.alertecata.fr"
        target="_blank"
        rel="noopener noreferrer"
        variant="success"
        className="ms-2"
        size="lg"
      >
        Télécharger
      </Button>
    </Container>
  </Navbar>
);

const Intro = () => (
  <section className="landing-intro landing-bg pt-5 pt-lg-6 pb-5 pb-lg-7">
    <Container className="landing-intro-content">
      <Row className="align-items-center">
        <Col lg="5" className="mx-auto">
          <Badge bg="" className="badge-soft-primary p-1">
            v1.0.0
          </Badge>

          <h1 className="my-4 text-xl">Alerter et prévenir en cas de risque</h1>

          <p className="text-lg">
            La première plateforme aux Antilles d'alerte et de prévention pour
            la gestion des risques.
          </p>

          <div className="my-4">
            <a
              href="https://apps.apple.com/fr/app/alerte-cata/id1631973910"
              target="_blank"
              rel="noreferrer"
              className="btn btn-dark btn-lg me-2"
            >
              <img
                height={40}
                src={appStoreLogo}
                alt="TypeScript"
                className="d-inline-block"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.alertecata"
              target="_blank"
              rel="noreferrer"
              className="btn btn-dark btn-lg me-1"
            >
              <img
                height={40}
                src={playStoreLogo}
                alt="TypeScript"
                className="d-inline-block"
              />
            </a>
          </div>
        </Col>
        <Col lg="7" className="d-none d-lg-flex mx-auto text-center">
          <div className=" pb-3">
            <img
              src={screenshotMixed}
              alt="AlerteCata Mockups"
              className="img-fluid"
            />
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

/*const ColorSchemesAndLayouts = () => (
  <section className="py-6 bg-white">
    <Container className="position-relative z-3">
      <Row>
        <Col md="12" className="mx-auto text-center">
          <Row>
            <div className="col-lg-10 col-xl-9 mx-auto">
              <div className="mb-4">
                <h2 className="h1 mb-3">
                  Multiple color schemes &amp; layouts
                </h2>
                <p className="text-muted fs-lg">
                  4 Pre-built color schemes and multiple layout types are
                  available to make this template your very own. All the color
                  schemes can take variation in color and styling, that can
                  easily be modified using Sass variables.
                </p>
              </div>
            </div>
          </Row>

          <Row>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/dashboard/default?theme=default"
              >
                <img
                  src={screenshotThemeDefault}
                  className="img-fluid rounded-lg landing-img"
                  alt="Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>Default</h4>
            </Col>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/dashboard/default?sidebarBehavior=compact"
              >
                <img
                  src={screenshotSidebarCompact}
                  className="img-fluid rounded-lg landing-img"
                  alt="Compact Sidebar Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>
                Compact sidebar{" "}
                <sup>
                  <Badge as="small" bg="primary">
                    New
                  </Badge>
                </sup>
              </h4>
            </Col>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/dashboard/default?theme=dark"
              >
                <img
                  src={screenshotThemeDark}
                  className="img-fluid rounded-lg landing-img"
                  alt="Dark Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>
                Dark{" "}
                <sup>
                  <Badge as="small" bg="primary">
                    New
                  </Badge>
                </sup>
              </h4>
            </Col>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/dashboard/default?theme=colored"
              >
                <img
                  src={screenshotThemeColored}
                  className="img-fluid rounded-lg landing-img"
                  alt="Colored Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>
                Colored{" "}
                <sup>
                  <Badge as="small" bg="primary">
                    New
                  </Badge>
                </sup>
              </h4>
            </Col>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/dashboard/default?theme=light"
              >
                <img
                  src={screenshotThemeLight}
                  className="img-fluid rounded-lg landing-img"
                  alt="Light Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>Light</h4>
            </Col>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/dashboard/default?sidebarPosition=right"
              >
                <img
                  src={screenshotSidebarRight}
                  className="img-fluid rounded-lg landing-img"
                  alt="Right Sidebar Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>
                Right sidebar{" "}
                <sup>
                  <Badge as="small" bg="primary">
                    New
                  </Badge>
                </sup>
              </h4>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
);

const Integrations = () => (
  <section className="pt-6">
    <Container>
      <Row>
        <Col md="10" className="mx-auto text-center">
          <div className="mb-5">
            <h2 className="h1">
              Launch faster with ready-to-deploy integrations
            </h2>
            <p className="text-muted fs-lg">
              AppStack React includes multiple ready-to-deploy integrations,
              including Redux, ESLint, Prettier, Auth0, Firebase Authentication,
              Amazon Cognito &amp; JSON Web Token Authentication.
            </p>

            <div className="my-4">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>React v17</Tooltip>}
              >
                <div className="landing-integration">
                  <img src={brandReact} alt="React v17" />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Redux</Tooltip>}
              >
                <div className="landing-integration">
                  <img src={brandRedux} alt="Redux" />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>React Router v6</Tooltip>}
              >
                <div className="landing-integration">
                  <img
                    src={brandReactRouter}
                    alt="React Router v6"
                    height="36"
                    style={{ margin: "2px 0" }}
                  />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Firebase Authentication</Tooltip>}
              >
                <div className="landing-integration">
                  <img src={brandFirebase} alt="Firebase Authentication" />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Amazon Cognito</Tooltip>}
              >
                <div className="landing-integration">
                  <img src={brandCognito} alt="Amazon Cognito" />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Auth0</Tooltip>}
              >
                <div className="landing-integration">
                  <img src={brandAuth0} alt="Auth0" />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>JSON Web Token</Tooltip>}
              >
                <div className="landing-integration">
                  <img src={brandJWT} alt="JSON Web Token" />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>ESLint</Tooltip>}
              >
                <div className="landing-integration">
                  <img src={brandESLint} alt="ESLint" />
                </div>
              </OverlayTrigger>
            </div>

            <Button
              variant="primary"
              size="lg"
              as="a"
              href="/docs/introduction"
              target="_blank"
              rel="noreferrer"
            >
              Open documentation
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

const Testimonials = () => (
  <section className="py-6">
    <Container>
      <div className="mb-5 text-center">
        <h2 className="h1">Developers love AppStack React</h2>
        <p className="text-muted fs-lg">
          Here's what some of our 5,000+ customers have to say about working
          with our products.
        </p>
      </div>
      <Row>
        <Col md="6" lg="4">
          <Card as="blockquote" className="landing-quote border">
            <Card.Body className="p-4">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <img
                    src={brandBootstrap}
                    width="48"
                    height="48"
                    alt="Bootstrap"
                  />
                </div>
                <div className="ps-3">
                  <h5 className="mb-1 mt-2">Nikita</h5>
                  <small className="d-block text-muted h5 fw-normal">
                    Head of Product
                  </small>
                </div>
              </div>
              <p className="lead mb-2">
                “We are totally amazed with a simplicity and the design of the
                template.{" "}
                <span>Probably saved us hundreds of hours of development.</span>{" "}
                We are absolutely amazed with the support Bootlab has provided
                us.”
              </p>

              <div className="landing-stars">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="4">
          <Card as="blockquote" className="landing-quote border">
            <Card.Body className="p-4">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <img
                    src={brandBootstrap}
                    width="48"
                    height="48"
                    alt="Bootstrap"
                  />
                </div>
                <div className="ps-3">
                  <h5 className="mb-1 mt-2">Alejandro</h5>
                  <small className="d-block text-muted h5 fw-normal">
                    Back-end Developer
                  </small>
                </div>
              </div>
              <p className="lead mb-2">
                “Everything is so properly set up that{" "}
                <span>
                  any new additions I'd make would feel like a native extension
                  of the theme
                </span>{" "}
                versus a simple hack. I definitely feel like this will save me
                hundredths of hours I'd otherwise spend on designing.”
              </p>

              <div className="landing-stars">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="4" className="d-block d-md-none d-lg-block">
          <Card as="blockquote" className="landing-quote border">
            <Card.Body className="p-4">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <img
                    src={brandBootstrap}
                    width="48"
                    height="48"
                    alt="Bootstrap"
                  />
                </div>
                <div className="ps-3">
                  <h5 className="mb-1 mt-2">Jordi</h5>
                  <small className="d-block text-muted h5 fw-normal">
                    Front-end Developer
                  </small>
                </div>
              </div>
              <p className="lead mb-2">
                “I ran into a problem and contacted support. Within 24 hours, I
                not only received a response but even an updated version that
                solved my problem and works like a charm.{" "}
                <span>Fantastic customer service!</span>”
              </p>

              <div className="landing-stars">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </section>
);

const DashboardsAndPages = () => (
  <section className="py-6 bg-white" id="demos">
    <Container className="position-relative z-3">
      <Row>
        <Col md="12" className="mx-auto text-center">
          <Row>
            <div className="col-lg-10 col-xl-9 mx-auto">
              <div className="mb-4">
                <h2 className="h1 mb-3">Multiple dashboards &amp; pages</h2>
                <p className="text-muted fs-lg">
                  5 Dashboards and 40+ Pages featuring various components,
                  giving you the freedom of choosing and combining. All
                  components can take variation in color and styling, that can
                  easily be modified using Sass.
                </p>
              </div>
            </div>
          </Row>

          <Row>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/dashboard/default"
              >
                <img
                  src={screenshotDashboardDefault}
                  className="img-fluid rounded-lg landing-img"
                  alt="Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>Default Dashboard</h4>
            </Col>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/dashboard/analytics"
              >
                <img
                  src={screenshotDashboardAnalytics}
                  className="img-fluid rounded-lg landing-img"
                  alt="Analytics Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>Analytics Dashboard</h4>
            </Col>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/dashboard/saas"
              >
                <img
                  src={screenshotDashboardSaaS}
                  className="img-fluid rounded-lg landing-img"
                  alt="SaaS Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>SaaS Dashboard</h4>
            </Col>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/dashboard/social"
              >
                <img
                  src={screenshotDashboardSocial}
                  className="img-fluid rounded-lg landing-img"
                  alt="Social Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>Social Dashboard</h4>
            </Col>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/dashboard/crypto"
              >
                <img
                  src={screenshotDashboardCrypto}
                  className="img-fluid rounded-lg landing-img"
                  alt="Crypto Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>
                Crypto Dashboard{" "}
                <sup>
                  <Badge as="small" bg="primary">
                    New
                  </Badge>
                </sup>
              </h4>
            </Col>
            <Col md="4" className="py-3">
              <Link
                className="d-block mb-3 mx-1"
                target="_blank"
                rel="noreferrer"
                to="/pages/projects"
              >
                <img
                  src={screenshotPageProjects}
                  className="img-fluid rounded-lg landing-img"
                  alt="Projects Bootstrap 5 Dashboard Theme"
                />
              </Link>
              <h4>
                Projects Dashboard{" "}
                <sup>
                  <Badge as="small" bg="primary">
                    New
                  </Badge>
                </sup>
              </h4>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
);*/

const Features = () => (
  <section className="py-6">
    <Container>
      <Row>
        <Col md="10" className="mx-auto text-center">
          <div className="mb-5">
            <h2 className="h1">Les fonctionnalités</h2>
            <p className="text-muted text-lg">
              Nous avons conçu la plateforme de gestion et de prévention des
              risques AlerteCata pour une prise en main rapide en intégrant des
              outils pratiques en cas de risques.
            </p>
          </div>

          <Row className="text-start">
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Info />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Actualités</h4>
                  <p className="fs-lg">
                    Véritable compagnon pour renforcer la culture du risque sur
                    nos territoires, elle vous informe et vous sensibilise à
                    partir des données officielles.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Eye />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Témoin d'un risque</h4>
                  <p className="fs-lg">
                    Chaque citoyen témoin d'un risque peut le signaler aux
                    autorités à travers l'application mobile en quelques
                    cliques.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Bell />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Alertes en cas de danger</h4>
                  <p className="fs-lg">
                    Recevez directement sur l'application les alertes
                    déclenchées par les autorités pour vous préparer aux
                    phénomènes et avertir vos proches.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Users />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Communauté de bénévoles</h4>
                  <p className="fs-lg">
                    La fiche profile de votre compte permet de renseigner vos
                    qualités et aptitudes utiles en cas de catastrophes. Les
                    autorités pourront faire appel à vos services pour aider ou
                    sauver des vies.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Wifi />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Villes connectées</h4>
                  <p className="fs-lg">
                    Pour recevoir les informations et les alertes en temps réel,
                    vous pouvez choisir une ou plusieurs villes.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <i data-feather="download-cloud"></i>
                  <Umbrella />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Liste des risques</h4>
                  <p className="fs-lg">
                    Découvrez et informez vous sur les risques autours de vous à
                    travers du contenu pédagogique et ludique.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
);

const Faq = () => {
  const [activeKey, setActiveKey] = useState("0");

  return (
    <section className="bg-white py-6">
      <Container>
        <div className="mb-5 text-center">
          <h2 className="h1">Foire Aux Questions</h2>
          <p className="text-muted fs-lg">
            Voici quelques-unes des réponses que vous pourriez rechercher.
          </p>
        </div>

        <Row>
          <Col md={9} lg={8} className="mx-auto">
            <Accordion activeKey={activeKey}>
              <Card className="border mb-3">
                <Card.Header
                  className="cursor-pointer"
                  onClick={() => setActiveKey("0")}
                >
                  <h6 className="mb-0">
                    Est-ce-que les serivces AlerteCata sont gratuits?
                  </h6>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="py-3">
                    Oui, la plateforme repose sur nos valeurs de solidarité et
                    de partage. L'ensemble des services en accès utilisateur
                    sont gratuits. À aucun moment nous ne vous demandons de
                    payer pour obtenir une information ou pour débloquer une
                    fonctionnalité.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="border mb-3">
                <Card.Header
                  className="cursor-pointer"
                  onClick={() => setActiveKey("1")}
                >
                  <h6 className="mb-0">Qui déclenche les alertes ?</h6>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="py-3">
                    Les seules à pouvoir diffuser les alertes via la plateforme
                    sont les autorités, tel le Maire, habilité pour la
                    sauvegarde de leur territoire. L'équipe d'AlerteCata n'a
                    qu'un rôle technique et ne déclenche pas d'alerte.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="border mb-3">
                <Card.Header
                  className="cursor-pointer"
                  onClick={() => setActiveKey("2")}
                >
                  <h6 className="mb-0">
                    Pourquoi ma ville n'apparait-elle pas dans la plateforme ?
                  </h6>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="py-3">
                    Les villes listées dans AlerteCata sont sous
                    conventionnement avec notre société pour l'utilisation de la
                    plateforme. La ville de Pointe-à-Pitre est la première ville
                    de déploiement de notre solution. L'ensemble du département
                    bénéficiera prochainement.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="border mb-3">
                <Card.Header
                  className="cursor-pointer"
                  onClick={() => setActiveKey("3")}
                >
                  <h6 className="mb-0">
                    L'application récupère t-elle mes données en permanence ?
                  </h6>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="py-3">
                    Non, nous respectons votre vie privée. L'application
                    récupère la position GPS de votre mobile à son ouverture
                    pour vous founir des informations correspondants à votre
                    environnement. De plus, cette méthode nous permet de
                    préserver la batterie de votre téléphone pour une plus
                    longue durée en cas de coupure d'électricité.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="border mb-3">
                <Card.Header
                  className="cursor-pointer"
                  onClick={() => setActiveKey("4")}
                >
                  <h6 className="mb-0">
                    Combien de temps dure la phase d'expérimentation ?
                  </h6>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="py-3">
                    La phase d'expérimentation est prévue pour 3 mois. C'est une
                    période qui permet aux villes sous convention AlerteCata
                    d'organiser les procédures de gestion de crise avec la
                    nouvelle plateforme digitale.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const Footer = () => (
  <section className="landing-footer py-6">
    <Container className="text-center landing-footer-container">
      <Row>
        <Col className="d-flex flex-column flex-sm-row justify-content-center">
          <a
            href="https://apps.apple.com/fr/app/alerte-cata/id1631973910"
            className="btn btn-dark btn-lg px-3 py-2 me-sm-4 mb-3"
          >
            <img
              src={appStoreLogo}
              className="dark-mode-img"
              alt="App Store"
              width="124"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.alertecata"
            class="btn btn-dark btn-lg px-3 py-2 mb-3"
          >
            <img
              src={playStoreLogo}
              className="dark-mode-img"
              alt="Google Play"
              width="139"
            />
          </a>
        </Col>
      </Row>
      <Row>
        <Col class="d-flex justify-content-center pt-4 mt-lg-3">
          <a href="#" class="btn btn-icon btn-secondary btn-facebook mx-2">
            <i class="bx bxl-facebook"></i>
          </a>
          <a href="#" class="btn btn-icon btn-secondary btn-instagram mx-2">
            <i class="bx bxl-instagram"></i>
          </a>
          <a href="#" class="btn btn-icon btn-secondary btn-twitter mx-2">
            <i class="bx bxl-twitter"></i>
          </a>
          <a href="#" class="btn btn-icon btn-secondary btn-youtube mx-2">
            <i class="bx bxl-youtube"></i>
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <p class="nav d-block fs-sm text-center pt-5 mt-lg-4 mb-0">
            <span class="opacity-80">
              AlerteCata © 2022 - Tous droits réservés. Service fournis par
            </span>{" "}
            <a
              class="nav-link d-inline-block p-0"
              href="https://www.alertecata.fr/"
              target="_blank"
              rel="noopener"
            >
              Web Expansion Agency SAS
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  </section>
);

const Home = () => {
  const { setTheme } = useTheme();
  const { setPosition, setBehavior } = useSidebar();
  const { setLayout } = useLayout();

  useEffect(() => {
    setTheme(THEME.DEFAULT);
    setPosition(SIDEBAR_POSITION.LEFT);
    setBehavior(SIDEBAR_BEHAVIOR.STICKY);
    setLayout(LAYOUT.FLUID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Navigation />
      <Intro />
      <Features />
      {/*<ColorSchemesAndLayouts />
      <Integrations />
      <Testimonials />
      <DashboardsAndPages />*/}
      <Faq />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
