import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";

const Support = () => (
  <React.Fragment>
    <Helmet title="Politique de confidentialité" />
    <Container fluid className="p-0">
      <Row>
        <Col lg={10} xl={8} className="col-xxl-7 mx-auto">
          <h1>Politique de confidentialité</h1>
          <hr className="my-4" />
          <p>
            ALERTE CATA est une marque déposée, un service édité, développé,
            fourni et commercialisé par WEB EXPANSION AGENCY S.A.S.
          </p>

          <p>
            En nous soumettant vos données personnelles, vous serez considéré
            comme ayant accordé votre autorisation - si nécessaire et le cas
            échéant - eu égard aux divulgations visées dans la présente
            politique de confidentialité.
          </p>

          <p>
            Conformément à la loi Informatique et Libertés du 6 janvier 1978
            modifiée, vous disposez d'un droit d'accès, de rectification,
            d'opposition à communication et de suppression des informations vous
            concernant sur simple demande à : WEB EXPANSION AGENCY S.A.S -
            Direction générale, Assainissement, 97 139 Les Abymes, GUADELOUPE,
            FRANCE.
          </p>
          <h2>
            Sur quoi porte la présente politique de confidentialité&nbsp;?
          </h2>
          <p>
            La présente politique de confidentialité a pour objectif de vous
            exposer le mode d'utilisation de vos informations personnelles qui
            sont collectées lorsque vous visitez l'un de nos sites Web ou l'une
            de nos applications mobile/Internet.
          </p>

          <p>
            La présente politique de confidentialité s'applique à la majorité de
            nos sites Web et applications mobiles/Internet. Toutefois, dans
            certains cas, il peut être nécessaire de mettre en place une
            politique de confidentialité légèrement différente. Lorsqu'un site
            Web ou une application mobile/Internet WEB EXPANSION AGENCY dispose
            d'une politique de confidentialité différente, il sera clairement
            indiqué que cette politique de confidentialité diffère de la
            présente politique de confidentialité générale de WEB EXPANSION
            AGENCY.
          </p>

          <p>
            Lorsque vous parcourez les sites Web et applications
            mobiles/Internet WEB EXPANSION AGENCY, nous vous invitons à
            consulter la politique de confidentialité de chaque site Web et
            applications que vous visitez ou utilisez, et vous invitons donc à
            ne pas supposer que la présente politique de confidentialité
            s'applique à tous les sites Web et applications mobiles/Internet WEB
            EXPANSION AGENCY.
          </p>
          <h2>Objectifs de la collecte de vos données</h2>
          <p>
            Nous collectons et utilisons des informations personnelles, en vous
            en informant préalablement et avec votre autorisation, afin de vous
            fournir plus efficacement les services ou les informations
            requis(es).
          </p>

          <p>
            Nous pourrions par conséquent collecter vos informations à caractère
            personnel lorsque vous :
          </p>
          <ul>
            <li>
              utilisez des produits et services de WEB EXPANSION AGENCY, ou dans
              le cadre de l'exploitation de ces derniers ;
            </li>
            <li>
              donnez à WEB EXPANSION AGENCY l'autorisation d'utiliser lesdites
              informations à caractère personnel (« opt-in ») ;
            </li>
            <li>
              répondez à nos demandes (SMS, courriels, questionnaires, enquêtes,
              etc …) ;
            </li>
            <li>
              interagissez avec les sites Web ou applications mobiles/Internet
              de Web Expansion Agency tels que la soumission d'un formulaire de
              demande, la fourniture d'un formulaire d'enquête, l'utilisation de
              services en ligne ;
            </li>
            <li>
              participez sur les pages des médias sociaux de WEB EXPANSION
              AGENCY tels que Facebook ou Twitter ;
            </li>
            <li>
              participez à des événements, des programmes de fidélisation ou de
              promotion de Web Expansion Agency ;
            </li>
            <li>
              fournissez des informations aux centres d'appels du Service
              Clients de Web Expansion Agency, ou auprès de tout tiers dans le
              cadre de la souscription ou l'exécution des produits et services
              de WEB EXPANSION AGENCY.
            </li>
          </ul>

          <p>
            Nous utilisons les données personnelles recueillis par le Produit ou
            Service pour différentes finalités :
          </p>
          <ul>
            <li>
              Pour vous aider à effectuer une transaction ou une commande ;
            </li>
            <li>
              Pour vous fournir et améliorer l’usage du Produit ou Service et
              vous apporter un éventuel support technique ;
            </li>
            <li>
              Pour vous communiquer les évolutions de nos Produits ou Services ;
            </li>
            <li>
              Pour vous permettre de personnaliser le Produit ou Service et vous
              permettre de sélectionner le contenu auquel vous souhaitez avoir
              accès ou que vous souhaitez partager avec un tiers ;
            </li>
            <li>
              Pour vous contacter au sujet de nos Produits ou Services ou
              d'autres produits et services de tiers analogues ;
            </li>
            <li>
              Pour prévenir et détecter les éventuelles menaces contre la
              sécurité de nos Produits ou Services, la fraude ou toute autre
              activité illicite, et
            </li>
            <li>
              Se conformer à nos obligations légales, régler les différends et
              faire appliquer nos contrats.
            </li>
          </ul>

          <p>
            La Société utilise les données personnelles des Utilisateurs pour
            les finalités suivantes :
          </p>
          <ul>
            <li>Fourniture des Services ;</li>
            <li>
              Prise en compte des commandes des services par les Utilisateurs ;
            </li>
            <li>Gestion et traitement des commandes ;</li>
            <li>Facturation ;</li>
            <li>
              Information sur la Société, les services, et les activités de la
              Société ;
            </li>
            <li>
              Réponse aux éventuelles questions/réclamations des Utilisateurs ;
            </li>
            <li>Elaboration de statistiques commerciales ;</li>
            <li>
              Gestion des demandes de droits d’accès, de rectification,
              d’opposition et de portabilité ;
            </li>
            <li>Gestion des impayés et du contentieux.</li>
          </ul>

          <p>
            Afin d'optimiser nos services, nous pouvons souhaiter utiliser vos
            données personnelles à des fins de marketing direct. Nous respectons
            la confidentialité et la protection de vos informations, et
            n'utilisons par conséquent vos données personnelles qu'à cette fin
            uniquement. Cette utilisation de vos données personnelles à des fins
            de marketing direct fera l'objet d'une information préalable et, si
            nécessaire, du recueil de votre autorisation préalable.
          </p>

          <p>
            De plus, si, à tout moment, vous souhaitez que nous arrêtions
            d'utiliser vos informations aux fins ci-dessus quelles qu'elles
            soient, vous pouvez nous contacter librement ainsi qu'il est indiqué
            en préambule des présentes. Nous arrêterons d'utiliser vos
            informations auxdites fins dans les plus brefs délais.
          </p>

          <p>
            En outre, les données personnelles collectées peuvent être
            (occasionnellement) transférées à des tiers sélectionnés, qui
            peuvent se situer en dehors de l'Espace Économique Européen (« EEE
            ») dans le cadre des services qui vous sont proposés à travers nos
            sites Web et applications mobiles/Internet. A titre d'exemple, cette
            situation peut survenir si l'un de nos serveurs se situe dans un
            pays en dehors de l'EEE ou si l'un de nos prestataires de services
            se situe dans un pays en dehors de l'EEE.
          </p>

          <p>
            Un tel transfert ne pourra être effectué par WEB EXPANSION AGENCY
            qu'en parfait respect des dispositions légales et réglementaires en
            la matière, et notamment de la Loi Informatique et Liberté du 6
            janvier 1978 modifiée.
          </p>

          <p>
            Lesdits tiers n'utiliseront vos informations personnelles à aucune
            fin autre que celles que nous avons convenues avec ces derniers et
            qui vous ont été indiquées. WEB EXPANSION AGENCY demande auxdits
            tiers de mettre en œuvre des niveaux de protection suffisants pour
            préserver la confidentialité et la protection de vos informations
            personnelles.
          </p>

          <p>
            Nous respectons vos informations personnelles et nous prendrons, par
            conséquent, des mesures pour nous assurer que vos droits au respect
            de la vie privée continuent d'être protégés si nous transférons
            ainsi vos informations en dehors de l'EEE. De plus, si vous utilisez
            nos services lorsque vous vous trouvez en dehors de l'EEE, vos
            informations peuvent être transférées en dehors de l'EEE afin de
            vous fournir lesdits services.
          </p>

          <p>
            Sous réserve des dispositions de la présente politique de
            confidentialité, nous ne divulguerons aucune information
            personnellement identifiable sans votre autorisation, à moins que
            nous y soyons légalement habilités ou tenus (par exemple, si nous y
            sommes tenus de par une procédure judiciaire ou dans le cadre de
            réquisitions ou interceptions légales).
          </p>

          <p>
            Nous tenons à vous assurer que nous n'utiliserons vos informations à
            aucune desdites fins si vous avez indiqué que vous ne souhaitez pas
            que nous utilisions ainsi vos informations lorsque vous nous les
            avez soumis, ou ultérieurement.
          </p>
          <h2>Informations collectées</h2>
          <p>
            Les informations à caractère personnel pouvant être recueillies par
            Web Expansion Agency comprennent les informations suivantes (cette
            liste n'étant toutefois pas limitative) :
          </p>
          <ul>
            <li>
              informations de contact (comme le nom, l'adresse, l'adresse
              électronique et le numéro de téléphone)
            </li>
            <li>
              informations d'identification (telles que le numéro
              d'identification du passeport, numéro d'immatriculation fiscale ou
              numéro de sécurité sociale, permis de conduire, date de naissance)
            </li>
            <li>
              informations démographiques (tels que l'âge, la gamme d'âge,
              l'état matrimonial, le sexe)
            </li>
            <li>photographies (le cas échéant)</li>
            <li>
              informations spécifiques au produit (comme préférence, groupe
              fermé d'utilisateurs, liste des personnes vous ayant parrainé pour
              la souscription du service, limite de crédit de communications …)
            </li>
            <li>informations bancaires</li>
            <li>
              informations de compte de service (telles que les soldes des
              comptes, historique des transactions, informations de crédit, de
              facturation, points de fidélité)
            </li>
          </ul>
          <h2>Confidentialité</h2>
          <p>
            WEB EXPANSION AGENCY garantit la confidentialité de vos
            informations, de quelque nature qu'elles soient, écrites ou orales,
            dont elle a connaissance et s'interdit de les communiquer, vendre,
            échanger, transférer ou partager avec des tiers, sous réserve des
            dispositions de la présente Politique de Confidentialité.
          </p>

          <p>
            Cet engagement de confidentialité ne s'applique pas aux informations
            :
          </p>
          <ul>
            <li>
              que la loi, la réglementation applicable ou une décision de
              justice obligerait à divulguer ;
            </li>
            <li>
              nécessaires pour répondre à des besoins d'obligations légales
              (réquisitions ou interceptions judiciaires) ;
            </li>
            <li>
              échangées avec des instituts de sondages, d'étude de marché et/ou
              avec des sociétés partenaires à des fins d'étude ou de promotion
              (hors courrier électronique.
            </li>
          </ul>

          <p>
            Les tiers ci-dessus énoncés doivent, dans tous les cas, convenir à
            un strict respect des engagements de confidentialité exigés par WEB
            EXPANSION AGENCY, et à n'utiliser vos informations à caractère
            personnel que pour les seules fins pour lesquelles elles ont été
            divulguées.
          </p>
          <h2>Transfert de données</h2>
          <p>
            Web Expansion Agency s'assurera que le transfert de toute donnée à
            caractère personnel s'effectue en conformité avec les lois et
            règlements applicables en la matière, et notamment dans le respect
            des dispositions de la Loi Informatique et Libertés du 6 janvier
            1978 modifiée.
          </p>

          <p>
            Toutefois, tout utilisateur de sites Web et applications
            mobiles/Internet de WEB EXPANSION AGENCY déclare accepter les
            caractéristiques et limites de l'Internet, dont il reconnaît
            notamment avoir une parfaite connaissance de la nature, et notamment
            de son caractère non sécurisé. Il appartient ainsi à chaque
            utilisateur de prendre toutes les mesures appropriées de façon à
            protéger ses propres données et/ou logiciels contre les risques
            d'intrusion ou de contamination par des virus, et de procéder aux
            sauvegardes nécessaires. De même, tout utilisateur de sites Web et
            applications mobiles/Internet de WEB EXPANSION AGENCY reconnaît être
            informé que l'intégrité, l'authentification et la confidentialité
            des informations, fichiers et données de toute nature qu'il souhaite
            échanger sur le réseau Internet ne peuvent être garantis sur ce
            réseau, qui est hors du contrôle de WEB EXPANSION AGENCY.
          </p>
          <h2>Conservation de vos données personnelles</h2>
          <p>
            Web Expansion Agency peut conserver certaines données personnelles
            pendant une période de temps spécifique, qui ne pourra toutefois
            excéder les durées maximales de conservation prévues par les lois et
            règlements applicables en la matière.
          </p>
          <h2>Sécurité de vos données personnelles</h2>
          <p>
            Nous accordons une grande importance à vos informations personnelles
            et nous nous engageons par conséquent à garantir un niveau de
            protection suffisant. Dans cette optique, nous avons mis en œuvre
            une technologie et des politiques visant à protéger votre
            confidentialité contre tout accès non-autorisé et usage abusif et
            nous actualiserons lesdites mesures au fil de l'évolution de la
            technologie, selon les besoins.
          </p>

          <p>
            Nous vous rappelons cependant que malgré nos engagements de garantir
            un niveau de protection suffisant au regard de la confidentialité de
            vos informations personnelles, aucun système de sécurité ne peut
            toutefois prévenir les failles de sécurité potentielles, notamment
            du fait des caractéristiques et limites de l'Internet.
          </p>
          <h2>Politique relative aux cookies</h2>
          <p>
            Nous utilisons le terme « cookies » pour désigner les cookies et
            autres technologies similaires couvertes par la Directive de l'UE
            sur la vie privée et les communications électroniques.
          </p>
          <h3>Qu'est-ce qu'un cookie ?</h3>
          <p>
            Les cookies sont des fichiers de données de petite taille que votre
            navigateur place sur votre ordinateur ou votre périphérique. Les
            cookies aident votre navigateur à parcourir un site Web et les
            cookies eux-mêmes ne peuvent pas collecter d'informations stockées
            sur votre ordinateur ou dans vos fichiers.
          </p>

          <p>
            Lorsqu'un serveur utilise un navigateur Web pour lire des cookies,
            ces derniers peuvent aider un site Web à fournir un service plus
            convivial. Pour protéger votre confidentialité, votre navigateur
            permet à un site Web d'accéder uniquement aux cookies qu'il vous a
            déjà envoyés.
          </p>
          <h3>Pourquoi utilisons-nous des cookies ?</h3>
          <p>
            Nous utilisons des cookies pour en savoir plus sur votre interaction
            avec notre contenu et nous aider à améliorer votre expérience
            lorsque vous visitez notre site Web et applications mobile/Internet.
          </p>

          <p>
            Les cookies mémorisent le type de navigateur que vous utilisez et
            les logiciels de navigation supplémentaires que vous avez installés.
            Ils mémorisent également vos préférences, comme la langue et la
            région, qui restent vos paramètres par défaut lorsque vous revenez
            sur le site Web et applications mobiles/Internet. Les cookies vous
            permettent également de noter des pages et de remplir des
            formulaires de commentaires.
          </p>

          <p>
            Certains des cookies que nous utilisons sont des cookies de session,
            qui sont temporaires et disparaissent lorsque vous fermez votre
            navigateur. D'autres sont des cookies persistants, stockés sur votre
            ordinateur pendant une durée plus longue. S'agissant des cookies
            permettant de mesurer l'audience, la durée de conservation des
            informations n'excédera pas six mois, au-delà les données seront
            rendues anonymes. Pour en savoir plus sur les différents types de
            cookies, vous pouvez vous reporter aux informations contenues dans
            la rubrique « Aide » de votre navigateur.
          </p>
          <h3>Comment rejeter et supprimer des cookies ?</h3>
          <p>
            Nous pouvons utiliser des cookies pour collecter des informations
            personnellement identifiables à votre sujet afin de permettre
            notamment de remplir automatiquement des champs de formulaires par
            exemple, et vous disposez par ailleurs d'un droit d'accès et
            d'opposition à ce sujet. Toutefois, si vous le souhaitez, vous
            pouvez choisir de rejeter ou bloquer les cookies définis par WEB
            EXPANSION AGENCY ou les sites Web de tout fournisseur tiers en
            modifiant les paramètres de votre navigateur - nous vous invitons à
            consulter la rubrique « Aide » de votre navigateur pour en savoir
            plus.
          </p>
          <p>
            Nous vous informons que la plupart des navigateurs acceptent
            automatiquement des cookies et si vous ne souhaitez pas que des
            cookies soient utilisés, vous aurez peut-être besoin de supprimer ou
            bloquer vous-même les cookies.
          </p>

          <p>
            Vous pouvez également vous rendre sur
            <a href="https://www.allaboutcookies.org">
              www.allaboutcookies.org
            </a>
            pour savoir comment supprimer ou rejeter des cookies et obtenir de
            plus amples informations sur les cookies en général. Pour obtenir
            des informations sur l'utilisation de cookies dans les navigateurs
            des téléphones portables et savoir comment rejeter ou supprimer ces
            cookies, nous vous invitons à consulter le manuel de votre
            téléphone.
          </p>

          <p>
            Nous vous informons toutefois que si vous rejetez l'utilisation de
            cookies, vous serez toujours en mesure de visiter nos sites Web et
            applications mobiles/Internet, mais certaines fonctionnalités
            peuvent ne pas fonctionner correctement.
          </p>
          <p>
            Web Expansion Agency n'assume aucune prestation de sauvegarde de
            données ou d'informations. VOUS DEVEZ PROCEDER A LA SAUVEGARDE DE
            VOS DONNÉES STOCKÉES ET INFORMATIONS SUR VOS PROPRES SUPPORTS
            (disque dur, cd, etc. …) afin de vous prémunir contre une éventuelle
            perte ou dégradations.
          </p>
          <h2>
            Modifications apportées à la présente Politique de Confidentialité
          </h2>
          <p>
            WEB EXPANSION AGENCY pourra périodiquement apporter des
            modifications à la présente politique de confidentialité. Si nous
            apportons toute modification importante à la présente politique de
            confidentialité et à la manière dont nous utilisons vos données
            personnelles, nous afficherons lesdites modifications sur la
            présente page et nous nous efforcerons de vous signaler toute
            modification importante. Web Expansion Agency vous invite à
            consulter régulièrement cette politique de confidentialité.
          </p>
          <h2>Informations sur la société</h2>
          <p>
            WEB EXPANSION AGENCY S.A.S
            <br />
            Société par actions simplifiées au capital de 10 000 €.
            <br />
            SIREN : 809 315 393 R.C.S. Pointe-à-Pitre
            <br />
            Siège social : 15 rue Abel LIBANY, Assainissement, 97 139 ABYMES
          </p>
          <p>Téléphone : +590 590 206 055</p>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default Support;
