import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import axios from "../../utils/axios";
import avatar from "../../assets/img/logo.png";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const VerificationPage = ({ t }) => {
  const [result, setResult] = useState(null);

  let { email, verifyCode } = useParams();

  useEffect(() => {
    if (email !== "") {
      axios
        .get(`account/verify/${email}/${verifyCode}`)
        .then((response) => {
          let data = response.data;
          setResult(
            "Votre compte a bien été validé. Vous pouvez vous connecter avec votre mot de passe."
          );
          return () => null;
        })
        .catch((error) => setResult(error.message));
    }
  }, [email, verifyCode]);

  return (
    <React.Fragment>
      <Helmet title={t("Account Verification")} />
      <div className="text-center mt-4">
        <h2>Bienvenue</h2>
        <p className="lead">
          Finalisation d'ouverture de votre compte sur la plateforme.
        </p>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={avatar}
                alt="Logo AlerteCata"
                className="img-fluid rounded-circle"
                width="132"
                height="132"
              />
            </div>
            <div>
              <p className="text-center ">{t(result)}</p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(VerificationPage);
